const App = () => {
  return (
    <div className="app">
      Hi, I'm Mohit Yadav! Check out on{" "}
      <a href="https://www.linkedin.com/in/mohityadav7">LinkedIn</a>
    </div>
  );
};

export default App;
